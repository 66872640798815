import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import TagColorRadioButtons from "./TagColorRadioButtons";
import FilterCommentMap from "./FilterCommentMap";
import GroupMemberTable from "./GroupMemberTable";
import HazardPhotoUploader from "./HazardPhotoUploader";
import { withTranslation } from 'react-i18next';

import Loader from "react-loader-spinner";

import "../styles/searchbox.scss";

const geocore = require("@mekasmith/geocore");
const isLiveApp = process.env.REACT_APP_TYPE === "LIVE" && process.env.REACT_APP_TYPE !== "VIEW"? true: false;

class FilterDialogForm extends React.Component {
  state = {
    map: null,
    marker: null,
    filterItemName: null,
    description: null,
    selectedColor: "",
    uploading: false,
    uploaded: false,
    photoURLs: [],
    acceptedFile: null,
  };

  componentDidMount() {
    const { t } = this.props;

    if (this.props.tag === t("Facility Information") && this.props.hasFacilities && this.props.editing) {
      this.getPhotoURLs(this.props.filterObj.id)
    }
  };

  componentDidUpdate(prevState) {
    const { t } = this.props;

    if (this.props.tag === t("Admin Comment") || (this.props.tag === t("Facility Information") && this.props.hasFacilities)) {
      if (!prevState.map) {
        window.google.maps.event.addListenerOnce(
          this.state.map,
          "tilesloaded",
          () => {
            if (!this.state.marker) {
              this.createInitialMarker();
            }
            this.initAutocomplete();
          }
        );
      }
    }
  };

  getPhotoURLs = async placeId => {
    const binaries = await geocore.objects.bins.list(placeId);
    const photoData = await this.getPhotoData(placeId, binaries);
    const photoURLs = [];
    if (photoData) {
      photoData.forEach(photo => {
        photoURLs.push(photo.url);
      });
      this.setState({ photoURLs })
    }
    return photoURLs
  };

  getPhotoData = async (placeId, binaries) => {
    if (binaries.length > 0) {
      let photoData = [];
      binaries.forEach(binary => {
        if (binary.includes("photo")) {          
          const urlPromise = geocore.objects.bins.url(placeId, binary)
          photoData.push(urlPromise);
        }
      });
      return Promise.all(photoData);
    } 
  };

  geocoreUploadPhoto = async (placeId, file, filename) => {
    try {
      const uploadedPhoto = await geocore.objects.bins.upload(placeId, 'photo.1', file, filename);

      this.setState({ 
        uploading: false, 
        uploaded: true, 
        photoURLs: uploadedPhoto.url 
      });

      return uploadedPhoto
    } catch (error) {
      console.log(error);
    }
  };

  onDrop = (acceptedFile, rejectedFile) => {
    this.setState({ uploading: true  });
    if(acceptedFile && this.props.editing) {
      this.geocoreUploadPhoto(this.props.filterObj.id, acceptedFile[0], acceptedFile[0].name);
    } else {
      this.setState({ uploading: false, uploaded: true, acceptedFile });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.props.editing) {
      this.geocoreEditSwitch(this.props.tag, this.props.hasFacilities);
    } else {
      this.geocoreAddSwitch(this.props.tag, this.props.hasFacilities);
    }
  };

  geocoreAddSwitch = (tag, hasFacilities) => {
    const { t } = this.props;

    switch (tag) {
      case t("Devices"):
        return this.geocoreAddGroup();
      case t("Facility Information"):
        if (hasFacilities) {
          return this.geocoreAddFacility();
        } else {
          return this.geocoreAddTag();
        }
      case t("Admin Comment"):
        return this.geocoreAddComment();
      case t("Incident"):
        return this.handleAddIncidentTag();
      default:
        break;
    }
  };

  geocoreEditSwitch = (tag, hasFacilities) => {
    const { t } = this.props;

    switch (tag) {
      case t("Devices"):
        return this.geocoreEditGroup();
      case t("Facility Information"):
        if (hasFacilities) {
          return this.geocoreEditFacility();
        } else {
          return this.geocoreEditTag();
        }
      case t("Admin Comment"):
        return this.geocoreEditComment();
      case t("Incident"):
        return this.geocoreEditIncidentTag();
      default:
        break;
    }
  };

  geocoreAddGroup = () => {
    let group = {
      id: this.newGroupId(),
      name: this.state.filterItemName,
      description: this.state.description,
      parent: {
        id: this.props.parentGroup.id
      }
    };
    geocore.groups
      .add(group, [], false)
      .then(group => {
        this.props.getGroups(true);
        this.props.toggleFilterForm();
      })
      .catch(error => {
        console.log(error);
      });
  };

  geocoreEditGroup = () => {
    let group = {
      name: this.state.filterItemName,
      description: this.state.description
    };
    geocore.groups
      .update(this.props.filterObj.id, group)
      .then(group => {
        this.props.getGroups(true);
        this.props.toggleFilterForm();
      })
      .catch(error => {
        console.log(error);
      });
  };

  geocoreAddTag = () => {
    let tag = {
      id: "TAG-TNHP-1-CT-" + this.props.parentGroup.id + "-" + new Date().getTime(),
      name: this.state.filterItemName,
      description: this.state.description,
      customData: {
        color: this.state.selectedColor? this.state.selectedColor : "blue"
      }
    };

    geocore.tags
      .add(tag)
      .then(tag => {
        this.props.getTags(true);
        this.props.toggleFilterForm();
      })
      .catch(error => {
        console.log(error);
      });
  };

  geocoreEditTag = () => {
    const { filterObj } = this.props;

    const updatedName = this.state.filterItemName? this.state.filterItemName : filterObj.name;
    const prevColor = filterObj.customData && filterObj.customData.color? filterObj.customData.color: "blue";
    const updatedColor = this.state.selectedColor? this.state.selectedColor : prevColor;
    let tag = {
      name: updatedName,
      description: this.state.description,
      customData: {
        color: updatedColor
      }
    };

    geocore.tags
      .update(filterObj.id, tag)
      .then(tag => {
        this.props.updateFacilityGroup(tag);
        this.props.updateFacilityMarkers(tag);
        this.props.toggleFilterForm();
      })
      .catch(error => {
        console.log(error);
      });
  };

  geocoreAddComment = () => {
    const latitude = this.state.marker
      ? this.state.marker.position.lat()
      : 35.6;
    const longitude = this.state.marker
      ? this.state.marker.position.lng()
      : 139.6;
    let place = {
      id: this.newCommentId(),
      name: this.state.filterItemName,
      description: this.state.description,
      address: "",
      point: {
        latitude,
        longitude
      },
      customData: {}
    };

    geocore.places.tags.addById = function(id, tagIds) {
      return geocore.post('/places/' + id + '/tags?tag_ids=' + encodeURIComponent(tagIds.join(',')), null);
    };
    let placeCommentTagId = 'TAG-TNHP-1-CMT-1-' + this.props.parentGroup.id;
    geocore.places
      .add(place, [this.commentTagId()])
      .then(place => {
        geocore.places.tags.addById(place.id, [placeCommentTagId]).then((res) => {
          this.props.addComment(place);
          this.props.toggleFilterForm();
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  geocoreEditComment = () => {
    const latitude = this.state.marker
      ? this.state.marker.position.lat()
      : 35.6;
    const longitude = this.state.marker
      ? this.state.marker.position.lng()
      : 139.6;
    const id = this.props.filterObj.id;
    let placeUpdate = {
      id,
      name: this.state.filterItemName,
      description: this.state.description,
      address: "",
      point: {
        latitude,
        longitude
      },
      customData: {}
    };
    geocore.places
      .update(id, placeUpdate)
      .then(place => {
        this.props.editComment(place);
        this.props.toggleFilterForm();
      })
      .catch(error => {
        console.log(error);
      });
  };

  geocoreEditIncidentTag = () => {
    const { filterObj } = this.props;

    const updatedName = this.state.filterItemName? this.state.filterItemName : filterObj.name;
    const prevColor = filterObj.customData && filterObj.customData.color? filterObj.customData.color: "blue";

    let tag = {
      name: updatedName,
      customData: {
        color: this.state.selectedColor? this.state.selectedColor : prevColor
      }
    };

    geocore.tags
      .update(filterObj.id, tag)
      .then(tag => {
        const incidentTag = {
          id: tag.id,
          name: tag.name,
          customData: {
            color: tag.customData.color
          }
        };
        this.props.updateIncidentPlaces(incidentTag, filterObj);
        this.props.toggleFilterForm();
      })
      .catch(error => {
        console.log(error);
      });
  };

  geocoreAddFacility = async () => {
    const { acceptedFile } = this.state;
    const date = new Date();
    const latitude = this.state.marker? this.state.marker.position.lat() : 35.6;
    const longitude = this.state.marker? this.state.marker.position.lng() : 139.6;
    let place = {
      id: "PLA-TNHP-1-" + this.props.parentGroup.id + "-" + date.getTime(),
      name: this.state.filterItemName,
      description: this.state.description,
      point: {
        latitude,
        longitude
      }
    };

    const newPlace = await geocore.places.add(place, [this.props.selectedFacilityGroup.id]);
    let photoData;

    if (acceptedFile) {
      photoData = await this.geocoreUploadPhoto(newPlace.id, this.state.acceptedFile[0], this.state.acceptedFile[0].name);
    }

    this.props.createNewFacilityMarker(this.props.selectedFacilityGroup, newPlace, photoData);
    this.props.addTableFacility(this.props.selectedFacilityGroup, newPlace);
  };

  geocoreEditFacility = () => {
    let date = new Date();
    const latitude = this.state.marker
      ? this.state.marker.position.lat()
      : 35.6;
    const longitude = this.state.marker
      ? this.state.marker.position.lng()
      : 139.6;
    let place = {
      id: "PLA-TNHP-1-" + this.props.parentGroup.id + "-" + date.getTime(),
      name: this.state.filterItemName,
      description: this.state.description,
      point: {
        latitude,
        longitude
      }
    };

    geocore.places
      .update(this.props.filterObj.id, place)
      .then(place => {
        this.props.editFacility(place, this.state.photoURLs, this.props.selectedFacilityGroup);
        this.props.editTableFacility(this.props.selectedFacilityGroup, place);
      })
      .catch(error => {
        console.log(error);
      });
  };

  geocoreDeleteItem = () => {
    this.props.toggleLoadingDelete(true);

    const filterObj = this.props.filterObj;
    const idType = filterObj.id.split("-")[0];
    if(idType === "PLA") {
      geocore.places.del(filterObj.id).then(deletedPlace => {
        this.props.handleCloseDelete(deletedPlace);
      });
    } else if (idType === "TAG" && filterObj.id.split("-")[3] === "INC") {
      geocore.tags.del(filterObj.id).then(deletedTag => {
        this.props.handleCloseDelete(deletedTag);
      });
    } else if (idType === "TAG" && filterObj.id.split("-")[3] === "CT") {
        var query = new geocore.places.query();
        query
          .withTagIds(filterObj.id)
          .page(1)
          .numberPerPage(100)
          .all()
          .then((places) => {
            if (places.length > 0) {
              var promises = [];
              for (var i = 0; i < places.length; i++) {
                var place = places[i];
                promises.push(geocore.places.del(place.id));
              }
              return Promise.all(promises);
            } else {
              console.log("No places to delete");
            }
          })
          .then(res => {
            return geocore.tags.del(filterObj.id);
          })
          .then(deletedTag => {
            this.props.handleCloseDelete(deletedTag);
          })
          .catch(e => {
            console.log(e);
            this.props.toggleLoadingDelete(false);
          });

    } else if (idType === "GRO") {
      geocore.groups.del(filterObj.id).then(deletedGroup => {
        this.props.handleCloseDelete(filterObj);
      });
    } else {
      this.props.toggleLoadingDelete(false);
    }
  };

  generateId = () =>
    Math.random()
      .toString(36)
      .substring(2, 14);

  newGroupId = () => "GRO-TNHP-1-G-" + this.generateId();

  newCommentId = () => "PLA-TNHP-1-CMT-" + this.generateId();

  newFacilityId = date =>
    "TAG-TNHP-1-CT-" + this.props.parentGroup.id + "-" + date.getTime();

  commentTagId = () => "TAG-TNHP-1-CMT-1-" + this.props.parentGroup.id;

  formTitleText = (tag, hasFacilities, editing) => {
    const { t } = this.props;

    switch (tag) {
      case t("Devices"):
        return t("Group Information");
      case t("Facility Information"):
        if (hasFacilities) {
          return t("Edit Facility Information");
        } else {
          return t("Category Information");
        }
      case t("Admin Comment"):
        if (editing) {
          return t("Edit admin comments");
        } else {
          return t("Admin comments update");
        }
      case t("Incident"):
        if (editing) {
          return t("Edit incident");
        } else {
          return "";
        }
      default:
        break;
    }
  };

  formLabelSwitch = (tag, hasFacilities) => {
    const { t } = this.props;
    switch (tag) {
      case t("Display"):
        return t("Group Name");
      case t("Facility Information"):
        if (hasFacilities) {
          return t("Facility Name");
        } else {
          return t("Category Information");
        }
      case t("Admin Comment"):
        return t("Title");
      case t("Incident"):
        return t("Incident Name");
      default:
        break;
    }
  };

  createInitialMarker = () => {
    if (this.state.map) {
      const map = this.state.map;
      const comment = this.props.filterObj;
      const lat = this.props.user.customData && this.props.user.customData.initialLat? parseFloat(this.props.user.customData.initialLat) : 35.6;
      const lng = this.props.user.customData && this.props.user.customData.initialLng? parseFloat(this.props.user.customData.initialLng): 139.6;
      const editingLat = this.props && this.props.filterObj && this.props.filterObj.point && this.props.filterObj.point.latitude? this.props.filterObj.point.latitude: null;
      const editingLong = this.props && this.props.filterObj && this.props.filterObj.point && this.props.filterObj.point.longitude? this.props.filterObj.point.longitude: null;
      const marker = new window.google.maps.Marker({
        position: {
          lat: editingLat? editingLat: lat,
          lng: editingLong? editingLong:lng
        },
        map,
        comment,
        animation: window.google.maps.Animation.DROP,
        draggable: true
      });

      this.setState({ marker });

      marker.addListener("dragend", () => {
        this.setState({ marker });
      });
    }
  };

  initAutocomplete = () => {
    if (this.state.map) {
      const map = this.state.map;

      // Link MapSearchBar to map and position it
      let input = document.getElementById("geosearch");
      let searchBox = new window.google.maps.places.SearchBox(input);

      // Listen for user's selection and retrieve details for that selection
      searchBox.addListener("places_changed", () => {
        let places = searchBox.getPlaces();

        if (places.length === 0) {
          return;
        }

        // For each place, get the icon, name and location.
        let bounds = new window.google.maps.LatLngBounds();
        places.forEach(place => {
          if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
          }

          let marker = this.state.marker;

          marker.setPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          });

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        // fitBounds works asynchronously and throws off the zoom, so set max zoom to the initialZoom, then reset
        const initialZoom = this.props.user.customData && this.props.user.customData.initialZoom? parseInt(this.props.user.customData.initialZoom): 6;

        map.setOptions({ maxZoom: initialZoom });
        map.fitBounds(bounds);
        map.setOptions({ maxZoom: map.maxZoom });
      });
    }
  };

  setSelectedColor = color => this.setState({ selectedColor: color });

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  renderPhotoUploader = () => {
    if (this.state.photoURLs.length > 0) {
      return <img src={this.state.photoURLs.length > 0? this.state.photoURLs[0] : null} alt="User Place" style={{ width: "50%", height: "100%" }} />              
    } else if (this.state.acceptedFile) {
      return <Typography>{`• ${this.state.acceptedFile[0].name}`}</Typography>
    } else {
      return (
        <HazardPhotoUploader 
          onDrop={this.onDrop}
          uploading={this.state.uploading}
          acceptedFile={this.state.acceptedFile}
        />
      )
    }
  };

  handleAddIncidentTag = () => {
    const selectedColor = this.state.selectedColor? this.state.selectedColor : "blue";
    this.props.geocoreAddIncidentTag(this.state.filterItemName, selectedColor);
    this.props.toggleFilterForm();
  };

  render() {
    const {t} = this.props;
    const defaultMapLocationLat = this.props.user.customData && this.props.user.customData.initialLat? parseFloat(this.props.user.customData.initialLat) : 35.6;
    const defaultMapLocationLong = this.props.user.customData && this.props.user.customData.initialLng? parseFloat(this.props.user.customData.initialLng): 139.6;
    const defaultMapZoom = this.props.user.customData && this.props.user.customData.initialZoom? parseInt(this.props.user.customData.initialZoom): 8;
    const editingLat = this.props && this.props.filterObj && this.props.filterObj.point && this.props.filterObj.point.latitude? this.props.filterObj.point.latitude: null;
    const editingLong = this.props && this.props.filterObj && this.props.filterObj.point && this.props.filterObj.point.longitude? this.props.filterObj.point.longitude: null;
    return (
      <div>
        <div className={isLiveApp? "add-group-form-container dark-mode":"add-group-form-container"}>
          <div>
            <Typography variant="h6">
              {this.formTitleText(
                this.props.tag,
                this.props.hasFacilities,
                this.props.editing
              )}
            </Typography>
          </div>
          <div>
            <form id="filter-dialog-form" onSubmit={(e) => this.handleSubmit(e)}>
              <div>
                <div>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      required
                      defaultValue={
                        this.props.filterObj ? this.props.filterObj.name : null
                      }
                      name="filter-item-name"
                      type="filter-item-name"
                      id="filterItemName"
                      label={this.formLabelSwitch(
                        this.props.tag,
                        this.props.hasFacilities
                      )}
                      autoComplete="off"
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                  </FormControl>
                  <div className="description-photo-row">
                    {this.props.tag !== t("Incident")?
                      <FormControl margin="normal" className={`filter-dialog-description ${this.props.tag === t("Facility Information") && this.props.hasFacilities? "with-photo": null}`}>
                        <TextField
                          multiline
                          defaultValue={
                            this.props.filterObj
                              ? this.props.filterObj.description
                              : null
                          }
                          rows={this.props.tag === t("Facility Information") && this.props.hasFacilities? "6": "4"}
                          rowsMax={this.props.tag === t("Facility Information") && this.props.hasFacilities? "6": "4"}
                          name="description"
                          type="description"
                          id="description"
                          label={
                            this.props.tag === t("Admin Comment") ? t("Comment") : t("Description")
                          }
                          autoComplete="off"
                          onChange={this.handleChange}
                          variant="outlined"
                        />
                      </FormControl>: <div></div>               
                    }
                    {this.props.tag === t("Facility Information") && this.props.hasFacilities?
                    <div className="filter-dialog-photo-container">
                      {this.renderPhotoUploader()}
                    </div>
                    : null}
                  </div>
                </div>
              </div>
              {(this.props.tag === t("Facility Information") || (this.props.tag === t("Incident") && !this.props.hasFacilities)) ? (
                <FormControl margin="normal" fullWidth>
                  <div className="pin-color-picker">
                    <div>
                      <Typography>{t("Tag colors")}</Typography>
                    </div>
                    <div>
                      <TagColorRadioButtons
                        setSelectedColor={this.setSelectedColor}
                        color={this.props.filterObj.customData && this.props.filterObj.customData.color? this.props.filterObj.customData.color: "blue"}
                      />
                    </div>
                  </div>
                </FormControl>
              ) : (
                null
              )}
              {this.props.tag === t("Admin Comment") ||
              (this.props.tag === t("Facility Information") && this.props.hasFacilities) ? (
                <div>
                {console.log("this.props", this.props)}
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      name="geosearch"
                      type="geosearch"
                      id="geosearch"
                      label={t("Search Location")}
                      autoComplete="off"
                      variant="outlined"
                    />
                  </FormControl>
                  <div className="comment-map-container">
                    <FilterCommentMap
                      id="comment-map"
                      options={{
                        center: {
                          lat: editingLat || defaultMapLocationLat,
                          lng: editingLong || defaultMapLocationLong
                        },
                        zoom: defaultMapZoom,
                        maxZoom: 30
                      }}
                      onMapLoad={map => {
                        this.setState({ map });
                      }}
                    />
                  </div>
                </div>
              ) : (
                null
              )}
            </form>
          </div>
        </div>
        <div>
          {this.props.tag === t("Devices") && this.props.editing ? (
            <GroupMemberTable
              user={this.props.user}
              parentGroup={this.props.parentGroup}
              selectedGroup={this.props.filterObj}
              getGroups={this.props.getGroups}
              getSelectedGroupUsers={this.getSelectedGroupUsers}
            />
          ) : (
            null
          )}
        </div>
        <Dialog
          open={this.props.openDelete}
          onClose={() => this.props.handleCloseDelete()}
          className={isLiveApp? "remove-user-modal dark-mode": "remove-user-modal"}
        >
          <div className="header">
            <Typography
              variant="h6"
            >
              {`「${this.props.filterObj.name}」${t("will be deleted")}`}
            </Typography>
          </div>
          <Divider />
          <div className="body">
            <Typography>
              {t("Are you sure you want to delete this?")}
            </Typography>
          </div>
          <Divider />
          <div className="footer">
            <div style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#0D6AAD", color: "white" }}
                onClick={() => this.props.handleCloseDelete()}
              >
                <CancelIcon />
                {t("Cancel")}
              </Button>
            </div>
            <div>
              <Button 
                variant="contained" 
                color="secondary"
                onClick={() => this.geocoreDeleteItem()}
                disabled={this.props.loadingDelete}
              >
                {this.props.loadingDelete ? (
                  <div>
                    <Loader type="TailSpin" color="white" height={15} width={15} />
                  </div>
                ) : null}
                <DeleteIcon />
                {t("Delete")}
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(FilterDialogForm)
