import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next';

const NewPlaceAlert = ({ alertNewPlace, alertCounter, hideAlert, getRecentPlaces }) => {
  const { t } = useTranslation();
  const [isFlashing, setIsFlashing] = useState(true);

  useEffect(() => {
    if(alertCounter) {
      // Trigger the flash and wobble effect
      setIsFlashing(true);
      // Stop the animation after a short delay (e.g., 2 seconds)
      const timer = setTimeout(() => setIsFlashing(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [alertCounter]);

  return (
    <div className={`new-places-found ${alertNewPlace ? "has-new" : "no-new"} ${isFlashing ? "flash-wobble" : ""}`}>
      <div className="close-new-places-alert">
        <button onClick={() => hideAlert()}>x</button>
      </div>
      <Typography>{t("There is a new report.")}</Typography>
      <div className="load-new-places">
        <Button onClick={() => { getRecentPlaces(); hideAlert(); }}>{t("New Update")}</Button>
      </div>
    </div>
  );
};

export default NewPlaceAlert;
