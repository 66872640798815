import React from "react";
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import RotateRightIcon from "@material-ui/icons/RotateRight";


import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

import { withTranslation } from 'react-i18next';

import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RecordButton from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import MuteIcon from '@material-ui/icons/VolumeOff';
import UnMuteIcon from '@material-ui/icons/VolumeUp';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ChatIcon from '@material-ui/icons/Chat';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HazardLiveSnapshot from "./HazardLiveSnapshot";
import AlertSnackbar from "./AlertSnackbar";

import { VideoSeekSlider } from 'react-video-seek-slider';
import "../styles/slider.scss";
import LiveTrackingMap from "./maps/LiveTrackingMap";

import firebase from "firebase/app";
import 'firebase/database';
import ExportKmlButton from "./ExportKmlButton";

//const Hls = require("hls.js");

const hasLevelName = (levelName, customName, t) => {

  let hazardName;
  switch (levelName) {
    case "緊急度1":
      hazardName = customName? customName : t("Alert 4");
      return hazardName;
    case "緊急度2":
      hazardName = customName? customName : t("Alert 3");
      return hazardName;
    case "緊急度3":
      hazardName = customName? customName : t("Alert 2");
      return hazardName;
    case "緊急度4":
      hazardName = customName? customName : t("Alert 1");
      return hazardName;
    default:
      break;
  }
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);
const iceCandidates = [];

class ModalLiveStreamDetails extends React.PureComponent {
  state = {
    ws: null,
    loading: false,
    hasStorageUrl: null,
    isHlsLive: null,
    rotateVideo: 0,
    socketIsSet: false,
    webRTCSocketClosed: false,
    hasError: false,
    webRTCError: false,
    isRecording: false,
    recordStartTime: 0,
    recordEndTime: 0,
    creatingClip: false,
    isMuted: false,
    isPlaying: false,
    videoDuration: null,
    currentTime: 0,
    viewComment: true,
    canViewComment: true,
    realtimeTracking: false,
    mouseIsDown: false,
    isResizing: false,
    map: null,
    marker: null,
    path: null,
    realtimeCoordinates: [],
    realtimePaths: [],
    visibleMap: false,
    webRtcPeer: null,
    pc: null,
    fallbackLat: 35.558988,
    fallbackLng: 139.942829,
    timeOffsetSeconds: 8,
    speak: false,
    localStreamAudio: null,
    mediaRecorder: null,
    localVideo: null
  };

  mapRef = React.createRef();

  checkForStorage = place => {
    if (place.video) {
      // storage was already processed on place load
      this.setState({
        loading: false,
        hasStorageUrl: place.video
      });
    } else {
      // we should check with axios if there is a file done yet here, else, alert user that it is still processing
      console.log("check with axios here!");
    }
  };

  componentDidMount() {
    this.dispose();
    window.addEventListener("resize", this.setVideoContainerHeight);
    // window.addEventListener('mouseup', this.handleResizeMouseUp);
    // window.addEventListener('mousemove', this.handleResizeMouseMove);
  }

  componentWillUnmount() {
    this.dispose();
    window.removeEventListener("resize", this.setVideoContainerHeight);
  }

  setVideoContainerHeight = () => {
    if (this.videoContainer) {
      var videoContainerHeight = this.videoContainer.offsetHeight;
      this.setState({
        videoContainerHeight: videoContainerHeight
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isWebRTC && this.props.roomInfo) {
      if (this.state.webRtcPeer === null) {
        console.log("CREATE WEBRTC PEER =====");
        this.createWebRtcPeer();
      }

      if (prevState.webRtcPeer === null && this.state.webRtcPeer) {
        console.log("STREAM CONNECT =====")
        this.streamConnect();
      }

    }

    /* // only for live streaming, final coords should be in the geocore place

    if (this.props.place && this.state.map && this.state.realtimeCoordinates.length === 0) {
      // first time opening the map while connected, get realtime data
      var a = this.props.place.placeId.split("PLA-TNHP-1-LIVE-")[1]
      var b = a.split("-");
      b.pop();
      var producerId = b.join('-');
      this.getFirebaseCoords(producerId,this.props.place.placeId);

    }
    */


    if (!prevState.map && this.state.map && this.state.realtimeTracking) {
      window.google.maps.event.addListenerOnce(
        this.state.map,
        "tilesloaded",
        () => {
          if (!this.state.marker) {
            if (this.props.isWebRTC && this.props.roomInfo) {
              // update map for realtime tracking
            } else {
              this.updateRealtimeMarker();
              this.updateRealtimeMapStorage();
            }
          }
        }
      );
    }
    
    if (!prevProps.open && this.props.open) {
      this.resetPlayer();
    }
  };

  getFirebaseCoords = (producerId, placeId) => {
    /* 
      Room Info [
        {
          "roomId":"PLA-TNHP-1-LIVE-USE-TNHP-1-T-XXXXX-1234566778",
          "namespace":"Telenet",
          "producer":"USE-TNHP-1-T-gdfsgdf4242",
          "groupName":"MapMotion",
          "username":"DeveloperUserMM",
          "title":null,
          "hazardLevel":"大至急！",
          "privateRoom":true
        }
      ]
    */

    const selectedRoomInfo = producerId && placeId;

    if (selectedRoomInfo) {
      this.realtimeRef = firebase.database().ref(`realtime/${producerId}/${placeId}`);
      
      this.realtimeRef.on("value", (snapshot) => {
  
        if (snapshot.val()) {
          // Stream data available
          this.updateRealtimeMapLive(snapshot.val());
        }
        
      }, (errorObject) => {
        console.log("The read failed: " + errorObject.code);
      });
    }
  };

  updateRealtimeMarker = () => {
    const map = this.state.map;
    const marker = this.state.marker;

    if (marker) {
      marker.setVisible(false);
      window.mc.removeMarker(marker);
      marker.setMap(null);
    }

    if (map) {
      const customData = this.props.user && this.props.user.customData;
      const initialCoords = this.props.place && this.props.place.geo;
      const RealtimeIcon = {
        anchor: new window.google.maps.Point(16, 24),
        url: "https://geocore-static.s3.ap-northeast-1.amazonaws.com/realtime-location-icon.svg",
        scaledSize: new window.google.maps.Size(32, 32)
      };

      const lat = initialCoords? initialCoords.latitude: 
                  customData && customData.initialLat? parseFloat(customData.initialLat): 
                  this.state.fallbackLat;
      const lng = initialCoords? initialCoords.longitude: 
                  customData && customData.initialLng? parseFloat(customData.initialLng): 
                  this.state.fallbackLng;

      const updatedMarker = new window.google.maps.Marker({
        position: {
          lat,
          lng 
        },
        icon: RealtimeIcon,
        map
      });

      this.setState({ marker: updatedMarker });
      map.setCenter({ lat, lng });
    }
  };

  updateRealtimeMapLive = realtimeData => {
    const updatedCoords = Object.values(realtimeData).sort((coordA, coordB) => coordA.duration - coordB.duration);
    
    const { realtimeCoordinates, map } = this.state;
    const updatedPaths = [];
    
    updatedCoords.forEach((updatedCoord, coordIndex) => {
      const currentCoord = updatedCoords[coordIndex];
      const prevCoord = updatedCoords[coordIndex - 1];
      const secondsLimit = 120;

      if (realtimeCoordinates.some(realtimeCoord => realtimeCoord.id === updatedCoord.id)) {
        // already added
      } else {
        // needs added
        if (prevCoord) {
          const secondsDiff = currentCoord.duration - prevCoord.duration;
    
          if (secondsDiff > secondsLimit) {
            const dottedLineSymbol = {
              path: "M 0,1 0,1",
              strokeOpacity: 1,
              scale: 4,
            };
    
            const dottedPath = new window.google.maps.Polyline({
              path: [
                {
                  lat: prevCoord.lat,
                  lng: prevCoord.lng
                },
                { 
                  lat: currentCoord.lat,
                  lng: currentCoord.lng
                },
              ],
              strokeOpacity: 0,
              strokeColor: "#43dcd7",
              icons: [
                {
                  icon: dottedLineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
              map
            });
    
            updatedPaths.push(dottedPath);
          } else {
            // Last coord in test data
            const realtimePath = new window.google.maps.Polyline({
              path: [
                {
                  lat: prevCoord.lat,
                  lng: prevCoord.lng
                },
                { 
                  lat: currentCoord.lat,
                  lng: currentCoord.lng
                },
              ],
              geodesic: true,
              strokeColor: "#43dcd7",
              strokeOpacity: 1.0,
              strokeWeight: 5,
              map
            });
    
            updatedPaths.push(realtimePath);
          }
        }
      }

      if (coordIndex === updatedCoords.length - 1) {
        
        if (this.state.marker) {
          this.setState(prevState => ({
            realtimeCoordinates: updatedCoords,
            realtimePaths: [
              ...prevState.realtimePaths,
              ...updatedPaths,
            ]
          }));

          this.updateMarkerBounds(currentCoord.lat, currentCoord.lng);
        } else {
          // Initial marker and map position has not been set yet
          const RealtimeIcon = {
            anchor: new window.google.maps.Point(16, 24),
            url: "https://geocore-static.s3.ap-northeast-1.amazonaws.com/realtime-location-icon.svg",
            scaledSize: new window.google.maps.Size(32, 32)
          };
    
          // most recent from firebase will be the first coord in the array
          const lat = currentCoord.lat;
          const lng = currentCoord.lng;
    
          const updatedMarker = new window.google.maps.Marker({
            position: {
              lat,
              lng 
            },
            icon: RealtimeIcon,
            map
          });

          this.setState(prevState => ({
            realtimeCoordinates: updatedCoords,
            realtimePaths: [
              ...prevState.realtimePaths,
              ...updatedPaths,
            ],
            marker: updatedMarker
          }), () => {
            this.updateMarkerBounds(lat, lng);
          });
        }
      }
    }); 
  };

  updateRealtimeMapStorage = () => {
    const { realtimeData } = this.props.place;

    const realtimeStorageData = JSON.parse(realtimeData);

    const realtimePaths = [];
    const secondsLimit = 120;
    
    realtimeStorageData.forEach((coord, coordIndex) => {
      const currentCoord = realtimeStorageData[coordIndex];
      const prevCoord = realtimeStorageData[coordIndex - 1];

      coord.duration -= this.state.timeOffsetSeconds;

      if (prevCoord) {
        const secondsDiff = currentCoord.duration - prevCoord.duration;
  
        if (secondsDiff > secondsLimit) {
          const dottedLineSymbol = {
            path: "M 0,1 0,1",
            strokeOpacity: 1,
            scale: 4,
          };
  
          const dottedPath = new window.google.maps.Polyline({
            path: [
              {
                lat: prevCoord.lat,
                lng: prevCoord.lng
              },
              { 
                lat: currentCoord.lat,
                lng: currentCoord.lng
              },
            ],
            strokeOpacity: 0,
            strokeColor: "#43dcd7",
            icons: [
              {
                icon: dottedLineSymbol,
                offset: "0",
                repeat: "20px",
              },
            ],
            currentVideoTime: coord.duration,
            map: this.state.map,
            visible: false,
          });
  
          realtimePaths.push(dottedPath);
        } else {
          // Last coord in test data
          const realtimePath = new window.google.maps.Polyline({
            path: [
              {
                lat: prevCoord.lat,
                lng: prevCoord.lng
              },
              { 
                lat: currentCoord.lat,
                lng: currentCoord.lng
              },
            ],
            geodesic: true,
            strokeColor: "#43dcd7",
            strokeOpacity: 1.0,
            strokeWeight: 5,
            currentVideoTime: coord.duration,
            map: this.state.map,
            visible: false,
          });
  
          realtimePaths.push(realtimePath);
        }
      }

      if (coordIndex === realtimeStorageData.length - 1) {
        this.setState({
          realtimeCoordinates: realtimeStorageData,
          realtimePaths,
        }, () => {
          this.setCurrentTime(this.state.currentTime);
        });
      }
    });
  };

  streamConnect = () => {
    console.log("connect");
    if (this.state.webRtcPeer) {
      this.props.socket.on("viewerResponse", message => {
        console.log("RECIEVED A MESSAGE!");
        if (message.response !== "accepted") {
          var errorMsg = message.message ? message.message : "Unknow error";
          console.warn("Call not accepted for the following reason: " + errorMsg);
          this.setState({
            webRTCError: true,
            loading: true
          });
        } else {
          this.setState({
            webRTCError: false,
            loading: false
          });
          if (this.state.webRtcPeer) {
            this.state.webRtcPeer.processAnswer(message.sdpAnswer)
          }
        }
      });
  
      this.props.socket.on("joinSuccessViewer", message => {
        console.log(message);
      });
  
      this.props.socket.on("iceCandidate", message => {
        if (this.state.webRtcPeer) {
          this.state.webRtcPeer.addIceCandidate(message.candidate);
        }
      });
  
      this.props.socket.on("stopCommunication", message => {
        //this.dispose();
        console.log(message);
      });
    }
  };

  createWebRtcPeer = () => {
    /*
    var options = {
      remoteVideo: this.webRTCPlayer,
      onicecandidate: this.onIceCandidateChange,
      configuration: {
        iceServers: [
          {
            urls: "turn:52.199.180.183:3478",
            username: "mapmotion",
            credential: "geocore"
          }
        ]
      }
    };

    let webRtcPeer = kurentoUtils.WebRtcPeerRecvonly(
      options,
      error => {
        if (error) {
          console.log("PEER CREATION ERROR");
        }

        this.setState({
          creatingViewer: false
        });
        console.log("PEER RECEV ONLY SENT NOW GEN OFFER");
        this.state.webRtcPeer.generateOffer(this.onOfferViewerSDP);
      }
    );

    this.setState({ webRtcPeer });
    */
  };

  dispose = () => {
    if (this.state.webRtcPeer) {
      console.log("STOPPING EVERYTHING!!!!");
      this.state.webRtcPeer.dispose();
      this.setState({
        webRtcPeer: null
      });

      if (this.realtimeRef) {
        this.realtimeRef.off();
      }
      // we also need to remove the listerners so they stop bubbling events and clear icecanidates.
      // This might be from the signal server
      // this.props.socket.on('viewerResponse'
      // this.props.socket.on('joinSuccessViewer'
      // this.props.socket.on('iceCandidate'
      // this.props.socket.on('stopCommunication'
    }
  };

  onOfferViewerSDP = (error, offerSdp) => {
    if (error) return this.onErrorRTC(error);
    var message = {
      id: "viewer",
      sdpOffer: offerSdp,
      caster_id: this.props.roomInfo[0].producer
    };
    this.props.socket.emit("message", message);
  };

  onIceCandidateChange = candidate => {
    console.log("Local candidate" + JSON.stringify(candidate));
    var message = {
      id: "onIceCandidate",
      candidate: candidate,
      caster_id: this.props.roomInfo[0].producer
    };
    this.props.socket.emit("message", message);
  };

  onErrorRTC = error => {
    console.log(error);
  };

  handleRotate = (direction, currentRotate) => {
    var newRotate = null;

    if (direction === "left") {
      newRotate = currentRotate - 90;
    } else {
      //it's right
      newRotate = currentRotate + 90;
    }

    if (newRotate > 360) {
      newRotate = -90;
    } else if (newRotate < -360) {
      newRotate = 90;
    }

    var videoContainerHeight = this.videoContainer.offsetHeight;
    this.setState({
      rotateVideo: newRotate,
      videoContainerHeight: videoContainerHeight
    });
  };

  handleWindowClose = () => {

    this.setState({speak: false});
    
    if(this.state.mediaRecorder) {
      this.state.mediaRecorder.stop();
    }

    if(this.state.localStreamAudio) {
      this.state.localStreamAudio.getTracks().forEach(function(track) {
        track.stop();
      });
    }

    if (this.props.isWebRTC) {
      this.dispose();
      this.props.leaveRoom(this.props.webRTCRoomId);
    }
    
    if (this.state.marker) {
      this.state.marker.setMap(null);
    }

    if (this.state.realtimePaths.length > 0) {
      this.state.realtimePaths.forEach(path => {
        path.setMap(null)
      });
    }

    if (this.player) {
      this.player.currentTime = 0;
    }

    this.resetPlayer();

    this.props.handleClose();
  };

  resetPlayer = () => {
    if (this.player) {
      this.player.currentTime = 0;
    }

    this.setState({
      loading: false,
      socketIsSet: false,
      webRTCSocketClosed: false,
      closedConnectForce: false,
      realtimeTracking: false,
      visibleMap: false,
      map: null,
      marker: null,
      realtimePaths: [],
      realtimeCoordinates: [],
      currentTime: 0,
      videoDuration: null,
      isPlaying: false
    });
  };

  handleFullScreen = type => {
    if (type ==="hls") {
      this.player.requestFullscreen();
    } else if (type === "webrtc") {
      this.webRTCPlayer.requestFullscreen();
    }
  };

  handleVideoDownload = async () => {
    const videoElement = document.querySelector(".storage-video");
    const sourceElement = videoElement ? videoElement.querySelector("source, video") : null;
    const videoSRC = sourceElement && sourceElement.hasAttribute("src") ? sourceElement.getAttribute("src") : null;

    if (videoSRC) {
        try {
            // Fetch the content of the video
            const response = await fetch(videoSRC);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            // Get the blob data of the video
            const videoBlob = await response.blob();

            // Create an object URL for the blob
            const blobURL = window.URL.createObjectURL(videoBlob);

            // Use the blob URL to trigger the download
            const link = document.createElement("a");
            link.href = blobURL;
            link.setAttribute("download", "hazard-video.mp4");
            link.style.display = "none"; 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // This step is necessary to release object URL resources
            setTimeout(() => {
                window.URL.revokeObjectURL(blobURL);
            }, 100);
        } catch (error) {
            console.error("There was a problem with the download:", error);
        }
    }
  };


  toggleSpeak = () => {
    if(this.state.speak) {
      this.setState({speak: false});
    } else {
      this.setState({speak: true});
    }
  }

  toggleRecord = () => {
    if(this.state.isRecording) {
      // recorder was started, stop recording and prompt user
      this.player.pause();
      this.setState({
        isRecording: false,
        creatingClip: true,
        recordEndTime: this.player.currentTime,
      });

      // call lambda function to process video, needs start, and end timestamps and video url
      var startTime = this.state.recordStartTime;
      var endTime = this.player.currentTime;

      var fileLocationArray = this.props.place.video[0].split("/");
      var geocore_id = fileLocationArray[3];
      var video_filename = fileLocationArray[4].split(".mp4")[0];

      axios({
        method: 'POST',
        url: `https://iv7p74vg8b.execute-api.ap-northeast-1.amazonaws.com/default/create-clip-from-live-storage`,
        headers: {
          "x-api-key": "FWgSioYDaOMyRBjpMssa46EYbKkEjgK3EDSLw8h9",
        },
        data: {
          geocore_id: geocore_id,
          video_filename: video_filename,
          start_time: startTime,
          end_time: endTime,
        }
      })
      .then(response => {
        // should get download url
        var download_url = response.data.signed_url;

        var newLink = document.createElement("a");
        newLink.href = download_url;

        newLink.click();
        newLink.remove();

        this.setState({creatingClip: false});
      })
      .catch(error => {
        // An error occured with Axios call to PublicDB
        console.log(error);
        this.setState({ creatingClip: false });
      });



    } else {
      // first time starting recording, make sure timestamps are reset
      // get the videos current timestamp
      //this.player
      this.player.play();
      this.setState({
        isRecording: true,
        creatingClip: false,
        recordStartTime: this.player.currentTime,
      });
    }
  };

  muteVideo = () => {
    this.setState(prevState => ({
      isMuted: !prevState.isMuted
    }), () => {
      this.state.isMuted? this.player.muted = true : this.player.muted = false;
    });
  };

  handlePlayClick = () => {
    if (this.player.paused) {
      this.setState({ isPlaying: true, viewComment: false, canViewComment: false });
      this.player.play();
      this.updateCurrentTimeStorage();
    } else {
      this.setState({ isPlaying: false, viewComment: true, canViewComment: true });
      this.player.pause();
    }
  };

  setCurrentTime = currentTime => {
    if (this.state.realtimeTracking && this.state.realtimePaths.length) {
      const currentPaths = this.state.realtimePaths.filter(path => {
  
        if (path.currentVideoTime <= currentTime) {
          path.setVisible(true);
          return true;
        } else {
          path.setVisible(false);
          return false
        }
  
      }).sort((pathA, pathB) => pathA.currentVideoTime > pathB.currentVideoTime? 1 : -1);
  
      const seekedPath = currentPaths[currentPaths.length - 1];

      if (seekedPath) {
        const seekedPathLat = seekedPath.getPath().getAt(1).toJSON().lat;
        const seekedPathLng = seekedPath.getPath().getAt(1).toJSON().lng;

        this.updateMarkerBounds(seekedPathLat,seekedPathLng);
      } else {
        const initialCoords = this.props.place && this.props.place.geo;

        const initialLat = initialCoords.latitude;
        const initialLng = initialCoords.longitude;

        this.updateMarkerBounds(initialLat, initialLng);
      }
    }
    
    if (this.player) {

      this.setState({ currentTime }, () => {
        this.player.currentTime = currentTime;
      });

    }
  };

  updateMarkerBounds = (lat, lng) => {
    const { map, marker, fallbackLat, fallbackLng } = this.state;
    
    if (map) {
      if (map.getBounds() && !map.getBounds().contains({ lat, lng })) {
        map.panTo({ lat, lng });
      }

      if (lat === fallbackLat && lng === fallbackLng) {
        // User has denied location tracking
        marker.setMap(null);

        const customData = this.props.user && this.props.user.customData;
        const initialCoords = this.props.place && this.props.place.geo;

        const lat = initialCoords? initialCoords.latitude: 
          customData && customData.initialLat? parseFloat(customData.initialLat): 
          fallbackLat;
        const lng = initialCoords? initialCoords.longitude: 
          customData && customData.initialLng? parseFloat(customData.initialLng): 
          fallbackLng;

        map.setCenter({ lat, lng });
      } else {
        marker.setPosition({ lat, lng });
      }
    }
  };

  updateCurrentTimeStorage = () => {
    this.player.ontimeupdate = () => {
      // fix for when a player might be playing while the user closes the model
      const videoDuration = this.player && this.player.duration === Infinity? null : this.player && this.player.duration? this.player.duration: null;
      
      if(this.player && this.player.currentTime) {
        this.setState({ 
          currentTime: this.player.currentTime, 
          videoDuration 
        });
        
        if (this.state.realtimeTracking) {
          const roundedCurrentTime = Math.floor(this.player.currentTime)
  
          if (this.state.realtimeCoordinates.length > 0) {
            const currentPath = this.state.realtimePaths.find(testPath => testPath.currentVideoTime === roundedCurrentTime);
            const currentCoord = this.state.realtimeCoordinates.find(testCoord => testCoord.duration === roundedCurrentTime);
            const alreadyAdded = currentPath && currentPath.visible;
  
            if (currentCoord && currentPath && !alreadyAdded) {
              this.updateMarkerBounds(currentCoord.lat, currentCoord.lng);
              currentPath.setVisible(true);
            }
          }
        }
      }

    };
  };

  toggleComment = () => {
    this.setState(prevState => ({
      viewComment: !prevState.viewComment
    }))
  }

  formatDuration = (duration) => {   
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  toggleRealtimeTracking = () => {
    // Only set up map once, if user toggles on and off, toggle visibility only
    if (this.state.realtimeTracking) {
      this.setState(prevState => ({
        visibleMap: !prevState.visibleMap,
      }));
    } else {
      this.setState({
        realtimeTracking: true,
        visibleMap: true
      });
    }
  };

  // handleResizeMouseDown = (e) => {
  //   if (!this.state.mouseIsDown) {
  //     this.setState({ mouseIsDown: true }, () => {
  //       if(this.state.mouseIsDown) {
  //         console.log("MOUSE HELD DOWN");
  //       }
  //     });
  //   }
  // };

  // handleResizeMouseUp = () => {
  //   if (this.state.mouseIsDown) {
  //     console.log("MOUSE RELEASED");
  //     this.setState({ mouseIsDown: false });
  //   }
  // };

  // handleResizeMouseMove = e => {
  //   if (this.state.mouseIsDown) {
  //     e.preventDefault();
  //     const offsetX = e.clientX - e.target.getBoundingClientRect().left;
  //     console.log("offsetX", e.offsetX, e);
  //     debugger;
  //     this.mapRef.current.style.width = `calc(40% - ${offsetX}px)`;
  //     this.videoContainer.style.width = `calc(60% + ${offsetX}px)`;
  //   }
  // };

  handleErrorAlertClick = () => {
    this.setState({ hasError: false });
  };
  
  render() {
    const tagId =
      this.props.open && this.props.place
        ? this.props.place.hazardLevel.id.split("-")
        : null;
    const customData = this.props.user && this.props.user.customData;
    const hazardLevel = tagId ? tagId[tagId.length - 1] : null;
    const rotateVideoTag =
      this.state.rotateVideo === 0
        ? ""
        : this.state.rotateVideo === 90
        ? "rotate-90"
        : this.state.rotateVideo === 180
        ? "rotate-180"
        : this.state.rotateVideo === 270
        ? "rotate-270"
        : this.state.rotateVideo === 360
        ? "rotate-360"
        : this.state.rotateVideo === -90
        ? "rotate-90-neg"
        : this.state.rotateVideo === -180
        ? "rotate-180-neg"
        : this.state.rotateVideo === -270
        ? "rotate-270-neg"
        : this.state.rotateVideo === -360
        ? "rotate-360-neg"
        : "";

    const hasRealtimeStorageData = !!this.props.open && !!this.props.place && !!this.props.place.realtimeData;
    const isLive = !!this.props.open && !!this.props.isWebRTC && !!this.props.roomInfo;
    const {t} = this.props;

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        className="place-modal live-streaming-modal"
      > 
        {this.state.creatingClip?
          <div className="creating-clip-loader">
            <div className="loader-holder">
              <span>Generating Clip</span>
              <CircularProgress />
            </div>
          </div>:null
        }
        <HazardLiveSnapshot
          open={this.props.snapshotOpen}
          close={this.props.handleSnapshotClose}
          download={() => this.props.handleVideoSnapshot(true)}
          createPlace={this.props.createPlace}
          createPlaceLoading={this.props.createPlaceLoading}
        />
        <AlertSnackbar
          isType="error"
          isOpen={this.state.hasError}
          handleClick={this.handleErrorAlertClick}
          message={this.state.errorMsg}
        />
        <div className="place-list-buttons">

          {this.props.place ?
            <LightTooltip 
              title="No realtime data"
              disableFocusListener
              disableTouchListener
              disableHoverListener={isLive || hasRealtimeStorageData} 
            >
              <span>
                <Button
                  variant="contained"
                  color="secondary"
                  className={`tracking-btn ${this.state.visibleMap? 'on': 'off'} ${isLive? '': hasRealtimeStorageData? '' : 'disabled'}`}
                  onClick={this.toggleRealtimeTracking}
                  disabled={isLive? false: !hasRealtimeStorageData}
                >
                  <div className={`tracking-status-container ${this.state.visibleMap? 'on': 'off'} ${isLive? '': hasRealtimeStorageData? '' : 'disabled'}`}>
                    <StatusIcon className={`status-icon ${isLive? '': hasRealtimeStorageData? '' : 'disabled'}`} />
                    <Typography className={`tracking-status ${this.state.visibleMap? 'on': 'off'} ${isLive? '': hasRealtimeStorageData? '' : 'disabled'}`}>
                      {this.state.visibleMap? "on": "off"}
                    </Typography>
                  </div>
                  {t("Real-time Tracking")}
                </Button>
              </span>
            </LightTooltip>
          : null}
          <Button onClick={this.handleWindowClose} className="close-button live" id="livestream_detail_window">
            <CloseIcon />
          </Button>
        </div>
        <div className={`live-stream-box ${rotateVideoTag}`}>
          {this.props.isWebRTC ? (
            <div>
              {this.props.roomInfo ? (
                <React.Fragment>
                  <div className="is-live-tag fw-stream">LIVE</div>
                  <div className="hazard-level-label">
                    <span className={`hazard-level-1`}>
                      {this.props.roomInfo[0].hazardLevel}
                    </span>
                  </div>
                  <Typography
                    variant="h4"
                    className="place-list-item-group-name"
                  >
                    {this.props.roomInfo[0].groupName}
                  </Typography>
                  <div className="place-list-item-username-container">
                    <PersonIcon />
                    <Typography variant="h5" className="username">
                      {this.props.roomInfo[0].username}
                    </Typography>
                  </div>
                  <div>
                    {this.props.roomInfo[0].title?
                      <Typography
                        variant="h2"
                        className="place-list-item-title"
                      >{this.props.roomInfo[0].title}</Typography>:null
                    }
                  </div>

                  <div>
                    <div
                      className={`live-stream-video-holder ${this.state.visibleMap? 'realtime-tracking' : ''}`}
                      ref={streambox => (this.videoContainer = streambox)}
                    >
                      <video
                        className={`livestream-video is-playing`}
                        style={
                            this.state.visibleMap &&                                 
                            (this.state.rotateVideo === 90 ||
                            this.state.rotateVideo === -90 ||
                            this.state.rotateVideo === 270 ||
                            this.state.rotateVideo === -270)? 
                          {
                            objectFit: "none"
                          } 
                            :
                          {
                            width:
                              this.state.rotateVideo === 90 ||
                              this.state.rotateVideo === -90 ||
                              this.state.rotateVideo === 270 ||
                              this.state.rotateVideo === -270
                                ? this.state.videoContainerHeight
                                : "100%"
                          }
                        }
                        ref={player => (this.webRTCPlayer = player)}
                        muted={true}
                        autoplay=""
                        controls={false}
                      ></video>
                      <div className="video-loading">
                        <div className="loader-holder">
                          <CircularProgress />
                        </div>
                      </div>
                    </div>
                    
                    <div className="stream-control-panel">
                      <div className="top-layer-controls">
                        <div className="rotate-and-full-controls">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              this.handleRotate("left", this.state.rotateVideo)
                            }
                            className="edit-button rotate rotate-left"
                          >
                            <RotateLeftIcon />
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => this.handleFullScreen("webrtc")}
                            className="edit-button fullscreen-video"
                          >
                            {t("Full Screen")}
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              this.handleRotate("right", this.state.rotateVideo)
                            }
                            className="edit-button rotate rotate-right"
                          >
                            <RotateRightIcon />
                          </Button>

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              this.toggleSpeak()
                            }
                            className={`edit-button rotate rotate-right ${this.state.speak? 'mic-on': 'mic-off'}`}
                          >
                          {this.state.speak?
                            <MicIcon />:
                            <MicOffIcon />}
                          </Button>

                        </div>
                      </div>
                    </div>
                    {this.state.realtimeTracking?
                      <div className={`tracking-map-container ${this.state.visibleMap? "visible-tracking-map": "hide-tracking-map"}`} ref={this.mapRef}>
                        {/* <div className="tracking-map-resize" onMouseDown={this.handleResizeMouseDown}></div> */}
                        <LiveTrackingMap
                          id="tracking-map"
                          options={{
                            center: {
                              lat: this.state.realtimeCoordinates.length > 0? 
                                    this.state.realtimeCoordinates[this.state.realtimeCoordinates.length - 1].lat: 
                                    customData && customData.initialLat? parseFloat(customData.initialLat): 
                                    this.state.fallbackLat,
                              lng: this.state.realtimeCoordinates.length > 0 ? 
                                    this.state.realtimeCoordinates[this.state.realtimeCoordinates.length - 1].lng: 
                                    customData && customData.initialLng? parseFloat(customData.initialLng): 
                                    this.state.fallbackLng
                            },
                            zoom: customData && customData.initialZoom? parseInt(customData.initialZoom): 6,
                            maxZoom: 30,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                          }}
                          onMapLoad={map => {
                            this.setState({ map });
                          }}
                          
                        />
                      </div>
                    : null}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          ) : (
            <React.Fragment>
              {this.props.place && this.props.place.streaming ? (
                <React.Fragment>
                  <div className="is-live-tag fw-stream">LIVE</div>
                  <div className="hazard-level-label">
                    <span className={`hazard-level-${hazardLevel}`}>
                      {hasLevelName(this.props.place.hazardLevel.name, this.props.place.hazardLevel.customName, t)}
                    </span>
                  </div>
                  <Typography
                    variant="h4"
                    className="place-list-item-group-name"
                  >
                    {this.props.place.groupName}
                  </Typography>
                  <div className="place-list-item-username-container">
                    <PersonIcon />
                    <Typography variant="h5" className="username">
                      {this.props.place.user}
                    </Typography>
                  </div>
                  <div>
                    {this.props.place.title?
                      <Typography
                        variant="h2"
                        className="place-list-item-title"
                      >{this.props.place.title}</Typography>:null
                    }
                  </div>
                  <div
                    className="live-stream-video-holder"
                    ref={streambox => (this.videoContainer = streambox)}
                  >
                    <video
                      className="livestream-video is-playing"
                      style={{
                        width:
                          this.state.rotateVideo === 90 ||
                          this.state.rotateVideo === -90 ||
                          this.state.rotateVideo === 270 ||
                          this.state.rotateVideo === -270
                            ? this.state.videoContainerHeight
                            : "100%"
                      }}
                      ref={player => (this.player = player)}
                      muted={true}
                      autoplay=""
                    ></video>
                    <div className="video-loading">
                      <div className="loader-holder">
                        <CircularProgress />
                      </div>
                    </div>
                  </div>
                  <div className="stream-control-panel">
                    <div className="top-layer-controls">
                      <div className="rotate-and-full-controls">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            this.handleRotate("left", this.state.rotateVideo)
                          }
                          className="edit-button rotate rotate-left"
                        >
                          <RotateLeftIcon />
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => this.handleFullScreen("hls")}
                          className="edit-button fullscreen-video"
                        >
                          {t("Full Screen")}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            this.handleRotate("right", this.state.rotateVideo)
                          }
                          className="edit-button rotate rotate-right"
                        >
                          <RotateRightIcon />
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.props.handleEditPlaceOpen}
                          className="edit-button bottom-bar edit"
                        >
                          {t("Edit")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.props.place &&
                  this.props.place.video &&
                  this.props.place.streamHasStorage ? (
                    <React.Fragment>
                      <div className="is-storage-tag fw-stream">{t("Storage")}</div>
                      <div className="hazard-level-label">
                        <span className={`hazard-level-${hazardLevel}`}>
                          {hasLevelName(this.props.place.hazardLevel.name, this.props.place.hazardLevel.customName, t)}
                        </span>
                      </div>
                      <Typography
                        variant="h4"
                        className="place-list-item-group-name"
                      >
                        {this.props.place.groupName}
                      </Typography>
                      <div className="place-list-item-username-container">
                        <PersonIcon />
                        <Typography variant="h5" className="username">
                          {this.props.place.user}
                        </Typography>
                      </div>
                      <div>
                        {this.props.place.title?
                          <Typography
                            variant="h2"
                            className="place-list-item-title"
                          >{this.props.place.title}</Typography>:null
                        }
                        {this.props.place.createTime?
                          <p
                            className="stream-place-view-created-at"
                          >{this.props.place.createTime}</p>:null
                        }
                      </div>
                      <div>
                        <div
                          className={`live-stream-video-holder ${this.state.visibleMap? 'realtime-tracking' : ''}`}
                          ref={streambox => (this.videoContainer = streambox)}
                        >

                          {this.props.place.duration?
                            <div className="duration-timestamps-large-player">{this.formatDuration(this.state.currentTime? this.state.currentTime: 0)}/{this.formatDuration(this.state.videoDuration? this.state.videoDuration: this.props.place.duration)}</div>:null
                          }
                          {this.props.place.comment && this.state.canViewComment?
                            <div className="comment-live-stream-button">
                            {this.state.viewComment?
                              <div className="comment-text-window">
                                <p>{this.props.place.comment}</p>
                              </div>:null
                            }
                            <Fab color="primary" onClick={this.toggleComment}>
                              {this.state.viewComment?
                                <HighlightOffIcon />:
                                <ChatIcon />}
                            </Fab>                            
                            </div>:null
                          }
                          <video
                            className="storage-video"
                            style={                            
                                this.state.visibleMap &&                                 
                                (this.state.rotateVideo === 90 ||
                                this.state.rotateVideo === -90 ||
                                this.state.rotateVideo === 270 ||
                                this.state.rotateVideo === -270)? 
                              {
                                objectFit: "none"
                              } 
                                :
                              {
                                width:
                                  this.state.rotateVideo === 90 ||
                                  this.state.rotateVideo === -90 ||
                                  this.state.rotateVideo === 270 ||
                                  this.state.rotateVideo === -270
                                    ? this.state.videoContainerHeight
                                    : "100%"
                              }
                            }
                            ref={player => this.player = player }
                          >
                            <source src={this.props.place.video} type={"video/mp4"} />
                          </video>
                          <div className="video-loading">
                            <div className="loader-holder">
                              <CircularProgress />
                            </div>
                          </div>
                        </div>
                        {this.state.realtimeTracking?
                          <div className={`tracking-map-container ${this.state.visibleMap? "visible-tracking-map": "hide-tracking-map"}`} ref={this.mapRef}>
                            {/* <div className="tracking-map-resize" onMouseDown={this.handleResizeMouseDown}></div> */}
                            <LiveTrackingMap
                              id="tracking-map"
                              options={{
                                center: {
                                  lat: this.props.place && this.props.place.geo? this.props.place.geo.latitude: 
                                       customData && customData.initialLat? parseFloat(customData.initialLat): 
                                       this.state.fallbackLat,
                                  lng: this.props.place && this.props.place.geo? this.props.place.geo.longitude: 
                                       customData && customData.initialLng? parseFloat(customData.initialLng): 
                                       this.state.fallbackLng
                                },
                                zoom: customData && customData.initialZoom? parseInt(customData.initialZoom): 6,
                                maxZoom: 30,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                              }}
                              onMapLoad={map => {
                                this.setState({ map });
                              }}
                              
                            />
                          </div>
                        : null}
                      </div>

                      <div className="stream-control-panel">
                        <div className="top-layer-controls">
                          <div className="seek-slider-controls">
                            <IconButton className="play-icon-button" onClick={this.handlePlayClick}>
                              {this.state.isPlaying? 
                                <PauseIcon/>:
                                <PlayIcon />
                              }                            
                            </IconButton>
                            <VideoSeekSlider
                              max={this.state.videoDuration? this.state.videoDuration : 100}
                              currentTime={this.state.currentTime}
                              onChange={time => this.setCurrentTime(time)}
                              secondsPrefix="00:00:"
                              minutesPrefix="00:"
                              limitTimeTooltipBySides={true}
                            />
                          </div>
                          <div className="rotate-and-full-controls">
                            <Button
                              variant="contained"
                              color="secondary"
                              className="edit-button bottom-bar video-download"
                              onClick={() => this.handleVideoDownload()}
                            >
                              {t("Download")}
                            </Button>
                            
                            {/*
                            <Button
                              variant="contained"
                              color="secondary"
                              className="edit-button bottom-bar video-snapshot"
                              onClick={this.props.handleSnapshotOpen}
                            > 
                              {t("Snapshot")}
                            </Button>
                            */}
                            <Button
                              variant="contained"
                              color="secondary"
                              className="edit-button bottom-bar video-mute"
                              onClick={this.muteVideo}
                            >
                              {this.state.isMuted?
                                <MuteIcon />:
                                <UnMuteIcon />
                              }
                            </Button>
                            {/*
                            <Button
                              variant="contained"
                              color="secondary"
                              className={`edit-button bottom-bar video-snapshot recording-${this.state.isRecording}`}
                              onClick={this.toggleRecord}
                            >
                            {this.state.isRecording?
                              <StopIcon />:
                              <RecordButton />
                            }
                            </Button>
                            */}
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                this.handleRotate(
                                  "left",
                                  this.state.rotateVideo
                                )
                              }
                              className="edit-button rotate rotate-left"
                            >
                              <RotateLeftIcon />
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => this.handleFullScreen("hls")}
                              className="edit-button fullscreen-video"
                            >
                              {t("Full Screen")}
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                this.handleRotate(
                                  "right",
                                  this.state.rotateVideo
                                )
                              }
                              className="edit-button rotate rotate-right"
                            >
                              <RotateRightIcon />
                            </Button>
                            {this.props.place && this.props.place.realtimeData? 
                              <ExportKmlButton realtimeStorageData={this.props.place.realtimeData} />                            
                            :null}
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={this.props.handleEditPlaceOpen}
                              className="edit-button bottom-bar edit"
                            >
                              {t("Edit")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div>
                      <div className="is-storage-tag fw-stream">{t("Storage")}</div>
                      <div className="hazard-level-label">
                        <span className={`hazard-level-${hazardLevel}`}>
                          {hasLevelName(this.props.place? this.props.place.hazardLevel.name : null, this.props.place? this.props.place.hazardLevel.customName : null, t)}
                        </span>
                      </div>
                      <Typography
                        variant="h4"
                        className="place-list-item-group-name"
                      >
                        {this.props.place ? this.props.place.groupName : ""}
                      </Typography>
                      <div className="place-list-item-username-container">
                        <PersonIcon />
                        <Typography variant="h5" className="username">
                          {this.props.place ? this.props.place.user : ""}
                        </Typography>
                      </div>
                      <div className="pending-wrapper">
                        <div className="pending-storage-placeholder"></div>
                      </div>
                      <div className="pending-control-wrapper">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.props.handleEditPlaceOpen}
                          className="edit-button bottom-bar edit"
                        >
                          {t("Edit")}
                        </Button>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </Dialog>
    );
  }
}
export default withTranslation()(ModalLiveStreamDetails);
